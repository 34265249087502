<template>
  <b-row>
    <b-col class="mb-3">
        <h5 class="text-center">{{ $i18n.locale == 'en' ? fair_info.fair_name_en : fair_info.fair_name_bn }}</h5>
        <h6 class="text-center font-weight-bold">{{ $t('externalTradeFair.evaluation_form') + ' ' + $t('globalTrans.upload') }}</h6>
    </b-col>
    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-overlay :show="loading">
          <b-form id="form" @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <ValidationProvider :name="$t('externalTradeFair.evaluation_form', 'en')" vid="visa_attachment" rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group class="row mb-2"
                            label-for="visa_attachment">
                            <template v-slot:label>
                                {{ $t('externalTradeFair.evaluation_form')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-file
                                plain
                                v-on:change="onFileChange($event)"
                                v-model="attachment_input"
                                accept=".jpg,.jpeg,.pdf"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            <div class="invalid-feedback d-block text-muted">
                                {{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is JPG/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট JPG/JPEG/PDF]' }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <ValidationProvider name="Comments" vid="comments" rules="">
                    <b-form-group
                      label-for="comments"
                      slot-scope="{ errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.comments') }}
                      </template>
                      <b-form-input
                          id="comments"
                          v-model="evaluation.comments"
                          :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col v-if="fair_info.upload_evaluation && fair_info.upload_evaluation.status === 3" xs="12" sm="12" md="12" lg="12" xl="12">
                  <h5 class="font-weight-bold">{{ $i18n.locale === 'en' ? 'Authority comments' : 'কর্তৃপক্ষের মন্তব্য' }}</h5>
                  <p>{{ fair_info.upload_evaluation.remarks }}</p>
                </b-col>
            </b-row>
            <b-row class="text-right">
              <b-col class="mt-2 text-right">
                  <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ (fair_info.upload_evaluation && fair_info.upload_evaluation.status === 3) ? $t('globalTrans.resubmit') : $t('globalTrans.save') }}</b-button>
                  <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-2')">{{ $t('globalTrans.cancel') }}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </ValidationObserver>
    </b-col>
  </b-row>
</template>
<script>

import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { fairEvaluationStoreApi, fairEvaluationUpdateApi } from '../../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  components: {},
  data () {
    return {
      valid: null,
      errors: [],
      baseUrl: internationalTradeFairServiceBaseUrl,
      fair_info: {},
      attachment_input: [],
      evaluation: {
        comments: '',
        attachment: ''
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getStoreData()
      this.fair_info = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    onFileChange (event) {
      const input = event.target
      if (input.files && input.files[0]) {
          this.evaluation.attachment = event.target.files[0]
      }
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      this.evaluation.participation_id = this.id
      const formData = new FormData()
      Object.keys(this.evaluation).map(key => {
          if (key === 'attachment') {
              formData.append(key, this.attachment_input)
          } else {
              formData.append(key, this.evaluation[key])
          }
      })

      if (!this.fair_info.upload_evaluation) {
        result = await RestApi.postData(internationalTradeFairServiceBaseUrl, fairEvaluationStoreApi, formData)
      } else {
        formData.append('_method', 'PUT')
        result = await RestApi.postData(internationalTradeFairServiceBaseUrl, `${fairEvaluationUpdateApi}/${this.fair_info.upload_evaluation.id}`, formData)
      }
      if (result.success) {
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-2')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getStoreData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
